.img-preview {
  display: flex;
  max-width: 100%;
  min-width: 100%;
  margin-top: 20px;
  height: 150px;
}

.img-preview-item {
  width: 16%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4c5056;
  margin: 5px;
  position: relative;
}

.img-preview-item .trash-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}

.trash-icon {
  color: red;
  cursor: pointer;
}

.edit-icon {
  color: green;
  cursor: pointer;
}

.img-preview-item img {
  max-width: 80%;
  min-width: 80%;
  max-height: 80%;
}

.upload-small-img {
  margin-top: 20px;
}

.tour-price {
  display: flex;
  margin-top: 30px;
}

.tour-price-item {
  width: 50%;
  padding-right: 20px;
}

.tour-price-item input{
  width: 100%;
}

.schedule-input {
  margin-top: 20px;
}

.schedule-item {
  padding-right: 20px;
}

.schedule-item input {
  width: 100%;
}

input {
  padding: 5px 10px;
  border-radius: 10px;
}

select {
  padding: 10px 10px;
  border-radius: 10px;
}
