.login-form {
  width: 40%;
}

.login-form-container {
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.loading-container {
  display: flex;
  justify-content: center;
}
