.page-container {
  display: flex;
  height: 100%;
  font-family: "Josefin Sans", serif !important;
  font-weight: 360;
  color: white;
}

.side-nav {
  height: 100%;
  width: 280px;
  background-color: #090057;
}

.page-body {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: scroll;
  color: black;
}

.logo {
  text-align: center;
}

.menu {
  margin-top: 50px;
}

.menu-item {
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #6d6df7;
}

.logout {
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200px;
  text-align: center;
  cursor: pointer;
}

.logout:hover {
  color: red;
}

.menu-active {
  background-color: #6d6df7;
}

