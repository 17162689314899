.paginator {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 0;
}

.paginator-bar {
  display: flex;
}

.paginator-item {
  padding: 5px 15px;
  background-color: #6d6df7;
  cursor: pointer;
  color: white;
  font-weight: 400;
  border: 1px solid #090057;
  margin: 10px 5px;
}

.page-item-active {
  background-color: #090057;
  font-weight: bold;
}
