table {
  width: 100%;
}

table tr td {
  border: 1px solid black;
  padding: 10px 5px;
}

.id-col {
  text-align: center;
  font-weight: bold;
}

table tr {
  cursor: pointer;
}

table thead {
  font-weight: bold;
}
